import React, {useEffect, useState} from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import PostcodePopup from '../SimplePostcodePopup';
import getClosestDealer from '../../../util/getClosestDealer';
import getClosestCoordinate from '../../../util/getClosestCoodinate';
import {Loader} from 'google-maps';
import {get} from 'lodash';
import dealer from '../../../data-restructure/pages/dealer';

const Container = styled.div`

`
    
const Calculator = (props) => {

    const {
        dealers,
        countryCode,
        make,
        price,
        model,
        variant,
        defaultDealerId
    } = props

    const [postcodeModal, setPostcodeModal] = useState(false) // false
    const [dealerId, setDealerId] = useState(process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.id : (defaultDealerId ? defaultDealerId : ''));
    const [geocoder, setGeocoder] = useState(null);

    useEffect(() => {
        const asyncLoad = async () => {
            const loader = new Loader(process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDvDPE1nXC0ejI_nWsFYf37isGY_XfJCp4', {});
            const google = await loader.load();
            setGeocoder(new google.maps.Geocoder());
        }
        asyncLoad();
    }, []);

    const getGeoDealerFromPostcode = (postcode) => {

        console.log('getGeoDealerFromPostcode', postcode, geocoder);

        const country = countryCode === 'AU' ? 'Australia' : 'New Zealand'
        if (!geocoder) {
            return
        }
        geocoder.geocode( { 'address': 'postalcode+' + postcode + country}, (results, status) => {
            if (status == 'OK') {
                const latFunc = get(results, '[0].geometry.location.lat', null);
                const lngFunc = get(results, '[0].geometry.location.lng', null);

                if (latFunc && lngFunc) {

                    const latLng = {
                        latitude: latFunc(),
                        longitude: lngFunc()
                    }

                    const closest = getClosestCoordinate(latLng, dealers.map(item => {
                        return {
                            ...item,
                            longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
                            latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
                        }
                    }), dealers);

                    if (closest && closest.item) {
                        console.log('closest setDealerId', closest);
                        setDealerId(get(closest, 'item.acf_dealers.id', ''))
                    } 
                } else {
                    // fallback?
                }
            } else {
                // fallback?    
            }
        });
    }

    const getDealerFromPostcode = (postcode) => {

        const closestDealer = getClosestDealer(postcode, dealers, countryCode);

        console.log('closestDealer: ', {closestDealer, postcode, dealers, countryCode} );

        if (closestDealer) {
            setDealerId(get(closestDealer, 'acf_dealers.id', ''))
        } else {
            getGeoDealerFromPostcode(postcode)
        }

    }
    
    useEffect(() => {
        // check if postcode exists;
        if (!defaultDealerId && !process.env.DEALER_SITE ) {
            const userLocation = localStorage.getItem('userLocation') ? JSON.parse(localStorage.getItem('userLocation')) : null;

            if (userLocation && userLocation.postcode) {
                const postcode = parseInt(userLocation.postcode);
                //grab
                getDealerFromPostcode(postcode)
            } else {
                setPostcodeModal(true);
            }
        } else {
            if (window.staticly) {
                setDealerId(window.staticly.dealerData.id);
            }
        }

    },[])

    let year = '';

    if (variant) {
        // Using match with regEx
        let matches = variant.match(/(\d+)(?!.*\d)/);
        
        // Display output if number extracted
        if (matches && matches[0]) {
            console.log('matches!', matches);
            year = matches[0];

            if (year.length === 2) {
                year = '20' + year;
            }
        }
    }

    console.log('calc props', props);

    return (
    	<Container>
            {postcodeModal &&
                <PostcodePopup
                    open={true}
                    onSubmit={(postcode) => {
                        console.log('postcode!', postcode);
                        getDealerFromPostcode(postcode);
                        setPostcodeModal(false);
                    }}
                    onClose={() => {
                        setDealerId('15146')
                        // setPricingKey('priceAUNSW');
                        // setPostcodeModal(false);
                        // const data = {
                        //   postcode: 2000
                        // }
                        // localStorage.setItem('userLocation', JSON.stringify(data));
                    }}
                />
            }

            <iframe src={`https://vroom.driveiq.com.au/m${dealerId ? dealerId : '15146'}?make=${make}&amount=${price}&model=${model}&variant=${variant}&buildYear=${year}`}></iframe>
            
        </Container>
    )
}
    
Calculator.propTypes = {
}
    
Calculator.defaultProps = {
    countryCode: 'AU',
    make: 'Jayco',
    price: '',
    model: '',
    variant: ''
}
    
export default Calculator